/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat, live chat bez obmedzenia',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',
  extCode: 'SK',
  key: 1,
  newsSectionUrl: 'https://m.weezchat.sk/news',
  footerText: 'Predplatné automaticky obnovované: 6€/týždeň (3xSMS 2€/týždeň)',
  footerLinks: [
    { value: 'Pravidlá', href: 'http://m.weezchat.sk/pravidla' },
    { value: 'Cenové podmienky', href: 'http://m.weezchat.sk/cenove_podmienky' },
    { value: 'Obchodné podmienky', href: 'http://m.weezchat.sk/obchodne_podmienky' },
    { value: 'Cookies', href: 'https://m.weezchat.sk/cookies' },
  ],
  LP: 'http://promo.weezchat.sk/pwzchatsk/weezquizz_sk/',
  redirectIfNoIframe: null,
  useBothSignUpMethod: true,
  avatarSize: 1200,
  gtm: 'GTM-KZTMRF2',
  showAllCities: false,
  completeProfileInputs: ['phoneNumber'],
  RTL: false,
  unsubscribeLink: 'http://m.weezchat.sk/pravidla',
freeSubscription : false,
  home: {
    app: {
      available: true,
      linkGoogle: 'https://play.google.com/store/apps/details?id=com.virgoplay.tchat.weezchat&hl=sk',
      linkApple: '',
    },
    facebook: {
      available: true,
      link: 'https://www.facebook.com/WeezchatSK/'
    },
    twitter: {
      available: true,
      link: 'https://twitter.com/Weezchat_SK'
    },
    instagram: {
      available: false,
      link: ''
    }
  },
  RGPD : {
    cookies: {
      available: true,
      link: 'https://m.weezchat.sk/cookies'
    }
  }
};
